import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'

export const client = new ApolloClient({
  uri: 'https://graph.arthur.live/dev',
  headers: {
    Authorization: `Bearer: ACCESS_TOKEN`,
  },
  fetch,
})
